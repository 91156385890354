<template>
	<div class="pricing-option">
		<span>Requires NTU</span>
		<template v-for="(pricingDetails, index) in service.pricing">
			<input type="radio" :id="'requires-ntu-' + index" :value="index" v-model="requiresNtu">
			<label :for="'requires-ntu-' + index">{{pricingDetails.ntu_required.charAt(0).toUpperCase() + pricingDetails.ntu_required.slice(1)}}</label>
		</template>
	</div>
	<div class="pricing-option" v-if="requiresNtu !== null">
		<span>Contract</span>
		<template v-for="(pricingDetails, index) in service.pricing[requiresNtu].pricing">
			<input type="radio" :id="'contract-months-' + index" :value="index" v-model="contractMonths">
			<label :for="'contract-months-' + index">{{pricingDetails.contract_months}} Months</label>
		</template>
	</div>
	<div class="speed-options" v-if="requiresNtu !== null && contractMonths !== null">
		<div class="speed-option" v-for="speed in service.pricing[requiresNtu].pricing[contractMonths].pricing">
			<div class="speed">{{Number(speed.speed).toLocaleString()}} Mbps</div>
			<div>${{Number(speed.monthly_cost).toLocaleString()}} Per Month</div>
			<div v-if="speed.installation_cost > 0" class="installation-cost">(Plus ${{Number(speed.installation_cost).toLocaleString()}} Installation)</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			service: Object
		},
		data() {
			return {
				requiresNtu: null,
				contractMonths: null
			}
		}
	}
</script>

<style scoped lang="scss">
	.pricing-option {
		margin-bottom:5px;
		
		span {
			font-weight:bold;
			margin-right:10px;
			
			&::after {
				content:":";
			}
		}
		
		label {
			margin:0 10px 0 5px;
		}
	}
	
	.speed-options {
		display:flex;
		flex-wrap:wrap;
		
		.speed-option {
			border:1px solid #000000;
			background-color:var(--highlight-color-dark);
			padding:10px;
			margin:10px;
			flex-shrink:1;
			min-width:180px;
			text-align:center;
			
			.speed {
				font-weight:bold;
			}
			
			.installation-cost {
				font-style:italic;
			}
		}
	}
	
</style>