<template>
	<h1 v-if="mode == 'general'">Service Qualification</h1>
	<div id="sq-address-wrapper">
		<GoogleMapsAddress @address-changed="addressSelected"></GoogleMapsAddress>
	</div>
	<div id="sq-address-error" v-if="error">
		{{error}}
	</div>
	<div class="loading-indicator" v-if="loading">
		<img src="@/assets/images/loading.gif">
	</div>
	<template v-if="mode == 'general'">
		<tabs v-if="Object.keys(data).length > 0" id="sq-wrapper">
			<tab title="Octane" v-if="data.octane">
				<service-qualification-provider :data="data.octane">
					<div v-if="data.octane.data">
						<standard-service-qualification-table :initial-services="data.octane.data.services" :positive-availability-values="['Available', 'Pass']"></standard-service-qualification-table>
					</div>
				</service-qualification-provider>
			</tab>
			<tab title="Frontier" v-if="data.frontier">
				<service-qualification-provider :data="data.frontier">
					<div v-if="data.frontier.data">
						<standard-service-qualification-table :initial-services="data.frontier.data.services" :positive-availability-values="['PASS', 'PASS - POA']" :include-pricing="includePricing"></standard-service-qualification-table>
					</div>
				</service-qualification-provider>
			</tab>
		</tabs>
	</template>
</template>

<script>
	import GoogleMapsAddress from '@/components/GoogleMapsAddress';
	import Tab from '@/components/Tabs/Tab';
	import Tabs from '@/components/Tabs/Tabs';
	import ServiceQualificationProvider from '@/components/ServiceQualification/ServiceQualificationProvider';
	import StandardServiceQualificationTable from '@/components/ServiceQualification/StandardServiceQualificationTable';
	import {mapGetters} from 'vuex';
	
	export default {
		props: {
			mode: {
				type: String,
				default: 'general'
			},
			customerType: {
				type: String,
				default: null
			}
		},
		emits: ['success', 'reset'],
		data() {
			return {
				loading: false,
				error: null,
				data: {}
			}
		},
		computed: {
			includePricing() { // Used to determine whether the authenticated user has the appropriate permission to view pricing information.
				return this.hasPermission('service-qualification', 'view-pricing');
			},
			requiresMode() { // Used to determine whether the "mode" option is required in the in the API call for the authenticated user. This is required if the user has access to both the general service qualification endpoint and the service qualification endpoint for service creation.
				return (this.hasPermission('service-qualification', 'perform') && this.hasPermission('services', 'create'));
			},
			...mapGetters(['hasPermission'])
		},
		components: {
			GoogleMapsAddress, Tabs, Tab, ServiceQualificationProvider, StandardServiceQualificationTable
		},
		methods: {
			async addressSelected(data) { // Called when an address is selected from the Google Places autocomplete widget.
				this.resetData();
				
				// If the selected address doesn't include a street number, display an error message.
				if(!data.address_components.hasOwnProperty('street_number')) {
					this.error = 'Please select a full address including the street number.'
					return;
				}
				
				// Display the loading indicator.
				this.loading = true;
				
				try {
					// Perform the API request to execute the service qualification.
					const response = await this.performServiceQualification(data);
					if(!response.data.address_found) { // If the given address wasn't found, display an error message.
						this.error = 'Unable to find the address.';
					} else { // If the given address was found, set the data for each service qualification provider.
						this.$emit('success', response.data);
						this.data = response.data;
					}
				} catch(error) { // If the API returned a non-200 response code, display a generic error message.
					this.error = 'An error has occurred.';
				}
				
				// Hide the loading indicator.
				this.loading = false;
			},
			async performServiceQualification(data) { // Perform the API request to execute the service qualification.
				// Generate the query string for the API call, with each argument encoded.
				let queryString = `formatted_address=${encodeURIComponent(data.formatted_address)}`;
				for(const type in data.address_components) {
					const value = data.address_components[type];
					queryString += `&${type}=${encodeURIComponent(value)}`;
				}
				
				// If the service qualification is being performed as part of a service creation, we also need to include the customer type of the customer that the service is being created for.
				if(this.mode == 'service') {
					queryString += `&customer-type=${this.customerType}`;
				}
				
				// Perform the API request and return the data.
				const mode = this.requiresMode ? `&mode=${this.mode}` : '';
				const response = await this.HTTP.get(`service-qualification?${queryString}${mode}`);
				return response.data;
			},
			resetData() { // Resets all of the component properties to their default values.
				this.error = null;
				this.data = {};
				this.$emit('reset');
			}
		}
	}
</script>

<style scoped lang="scss">
	#sq-address-wrapper {
		margin-bottom:30px;
	}
	
	#sq-address-error {
		text-align:center;
		color:#FF0000;
	}
	
	#sq-wrapper {
		margin-bottom:60px;
	}
</style>