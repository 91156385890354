<template>
	<div class="address-wrapper">
		<GMapAutocomplete
			placeholder="Start typing an address..."
			@place_changed="setPlace"
			:options="{
				fields: ['address_components', 'formatted_address'],
				types: ['address'],
				componentRestrictions: {
					country: 'au'
				}
			}"
		></GMapAutocomplete>
	</div>
</template>

<script>
	// Object to map field types in the Google API response to the arguments used by the Pennytel API.
	const addressFieldMappings = {
		'subpremise': 'unit',
		'street_number': 'street_number',
		'route': 'street_name',
		'locality': 'suburb',
		'administrative_area_level_1': 'state',
		'postal_code': 'post_code',
		'country': 'country'
	};
	
	export default {
		methods: {
			setPlace(place) { // Called when an address is selected from the Google Places autocomplete widget.
				const addressComponents = {};
				
				// Loop through each field from the Google API response, and if it's a field that's used by the Pennytel API, add it to the addressComponents object.
				for(const addressComponent of place.address_components) {
					for(const index in addressComponent.types) {
						const type = addressComponent.types[index];
						if(addressFieldMappings.hasOwnProperty(type)) {
							const field = addressFieldMappings[type];
							addressComponents[field] = (field == 'street_name') ? addressComponent.long_name : addressComponent.short_name;
						}
					}
				}
				
				// Send an event to the parent component to signify that an address was selected.
				this.$emit('address-changed', {formatted_address: place.formatted_address, address_components: addressComponents});
			}
		}
	}
</script>

<style scoped lang="scss">
	.address-wrapper {
		text-align:center;
		
		input {
			width:50%;
			height:40px;
			padding:10px;
		}
	}
</style>