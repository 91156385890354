<template>
	<table class="sq-result">
		<thead>
			<tr>
				<th>Product</th>
				<th>Availability</th>
				<th>Notes</th>
				<th v-if="includePricing">Show Pricing</th>
			</tr>
		</thead>
		<tbody>
			<template  v-for="(service, index) in services">
				<tr :class="{'service-available': positiveAvailabilityValues.includes(service.availability), 'service-unavailable': !positiveAvailabilityValues.includes(service.availability)}">
					<td>{{service.product}}</td>
					<td>{{service.availability}}</td>
					<td class="notes">
						<div v-html="service.notes ? service.notes.replace(/(?:\r\n|\r|\n)/g, '<br />') : 'N/A'"></div>
						<div v-if="service.notes && service.formatted_notes && service.show_details" class="formatted-notes">
							<div v-for="accessType in standardiseFormattedNotes(service.formatted_notes)">
								<h2 v-if="Array.isArray(service.formatted_notes) && accessType.access_type">{{accessType.access_type}}</h2>
								<div v-for="note in standardiseFormattedNotes(accessType, 'notes')">
									<div v-if="typeof note === 'string'" v-html="note.replace(/(?:\r\n|\r|\n)/g, '<br />')"></div>
									<template v-else>
										<div class="formatted-note-question">{{note.question}}</div>
										<div :class="{'formatted-note-success': note.success, 'formatted-note-error': !note.success}">{{note.answer}}</div>
									</template>
								</div>
							</div>
						</div>
						<div v-if="service.notes && service.formatted_notes && !service.show_details" @click="service.show_details = true" class="detailed-notes">detailed notes...</div>
					</td>
					<td v-if="includePricing" class="pricing-disclosure">
						<disclosure-button :disabled="!(positiveAvailabilityValues.includes(service.availability) && service.pricing)" @status-changed="(enabled) => setPricingVisibility(index, enabled)" :toggle-enabled="showPricingIndex == index"></disclosure-button>
					</td>
				</tr>
				<tr v-if="includePricing && positiveAvailabilityValues.includes(service.availability) && service.pricing" v-show="showPricingIndex == index" class="pricing-options">
					<td colspan="4">
						<service-qualification-pricing :service="service"></service-qualification-pricing>
					</td>
				</tr>
			</template>
		</tbody>
	</table>
</template>

<script>
	import DisclosureButton from '@/components/DisclosureButton';
	import ServiceQualificationPricing from '@/components/ServiceQualification/ServiceQualificationPricing';
	
	export default {
		props: {
			initialServices: Object,
			positiveAvailabilityValues: Array,
			includePricing: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				services: [],
				showPricingIndex: null
			}
		},
		components: {
			DisclosureButton, ServiceQualificationPricing
		},
		created() {
			// Sort the services passed into the component to move the available services to the top.
			this.services = this.initialServices.sort((a, b) => {
				const positiveValues = this.positiveAvailabilityValues;
				if(!positiveValues.includes(a.availability) && positiveValues.includes(b.availability)) {
					return 1;
				} else if(positiveValues.includes(a.availability) && !positiveValues.includes(b.availability)) {
					return -1;
				} else {
					return 0;
				}
			});
		},
		methods: {
			setPricingVisibility(productIndex, enabled) { // Called when one of the pricing disclosure buttons is clicked.
				this.showPricingIndex = enabled ? productIndex : null; // Sets the index of the product that was enabled, or NULL if it was disabled.
			},
			standardiseFormattedNotes(notes, notesProperty = null) { // If there are formatted notes for more than one access type, an array of objects is returned, otherwise a single object is returned. This normalises both versions into an array.
				if(notesProperty !== null && notes[notesProperty]) { // If a property name was provided, then "notes" is an object, which the notes in the given property.
					notes = notes[notesProperty];
				}
				
				return Array.isArray(notes) ? notes : [notes];
			}
		}
	}
</script>

<style scoped lang="scss">
	.sq-result {
		border-collapse:collapse;
		margin-top:10px;
		width:100%;
	
		th, td {
			border:1px solid #000000;
			padding:10px;
		}
		
		thead tr {
			background-color:var(--highlight-color-dark);
		}
		
		tr.service-available {
			background-color:#D1FFD1;
		}
		
		tr.service-unavailable {
			background-color:#FFD1D1;
		}
		
		tr.pricing-options {
			background-color:var(--highlight-color-light);
		}
		
		td.notes {
			position:relative;
			
			.detailed-notes {
				position:absolute;
				bottom:5px;
				right:5px;
				color:#0000FF;
				cursor:pointer;
			}
		}
		
		td.pricing-disclosure {
			text-align:center;
		}
		
		.formatted-notes {
			margin-top:20px;
			
			& > div > div {
				margin-top:10px;
			}
		}
		
		.formatted-note-question {
			font-weight:bold;
		}
		
		.formatted-note-success {
			color:#008000;
		}
		
		.formatted-note-error {
			color:#FF0000;
		}
	}
</style>