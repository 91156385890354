<template>
	<div class="error" v-if="data.error">
		{{data.error}}
	</div>
	<div v-else>
		Address: {{data.data.address}}
		<slot></slot>
	</div>
</template>

<script>
	export default {
		props: {
			data: {
				type: Object,
				default: {}
			}
		}
	}
</script>